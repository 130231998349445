import { Typography } from '@mui/material';
import React from 'react';

const Support = () => {
  return (
    <div>
      <Typography variant='h4'>Support</Typography>
      {/* Add support information and FAQs */}
    </div>
  );
};

export default Support;
