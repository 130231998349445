import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';


const TermsOfService = () => {
    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Smart Phone Dash Cam Terms Of Service
            </Typography>
            <Typography variant="body1">
                Last Updated: January 2, 2024
            </Typography>
            <br />
            <Typography variant="body1">
                Welcome to Smart Phone Dash Cam! Before using our Android application, please carefully read and understand the following Terms of Service ("Terms"). These Terms govern your use of the Smart Phone Dash Cam application (the "App") and its related services provided by Salter Diaz Solutions LLC ("we," "us," or "our").
            </Typography>
            <br />
            <Typography variant="body1">
                By downloading, installing, or using the App, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use the App.
            </Typography>
            <br />
            <Typography variant="body1">
                <strong>1. License</strong>
            </Typography>
            <br />
            <Typography variant="body1">
                Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable, revocable license to use the App for personal, non-commercial purposes. You may not sublicense, sell, or transfer your rights in the App.
            </Typography>
            <br />
            <Typography variant="body1">
                <strong>2. User Registration</strong>
            </Typography>
            <br />
            <Typography variant="body1">
                Certain features of the App may require you to register an account. You agree to provide accurate and complete information during the registration process and to update such information to keep it accurate and current.
            </Typography>
            <br />
            <Typography variant="body1">
                <strong>3. Privacy</strong>
            </Typography>
            <br />
            <Typography variant="body1">
                We take your privacy seriously. Our Privacy Policy explains how we collect, use, and disclose information about you. By using the App, you agree to the terms of our Privacy Policy, which can be found here: {<Link to="/privacy" >Privacy Policy</Link>}
            </Typography>
            <br />
            <Typography variant="body1">
                <strong>4. User Conduct</strong>
            </Typography>
            <br />
            <Typography variant="body1">
                You agree not to:
            </Typography>
            <ul>
                <li>Violate any applicable laws or regulations.</li>
                <li>Infringe on the intellectual property rights of others.</li>
                <li>Use the App for any illegal or unauthorized purpose.</li>
                <li>Transmit viruses, worms, or any other code that may harm the App or its users.</li>
                <li>Attempt to gain unauthorized access to the App or its related systems.</li>
            </ul>
            <br />
            <Typography variant="body1">
                <strong>5. Content Ownership</strong>
            </Typography>
            <br />
            <Typography variant="body1">
            You retain ownership of any content you submit or upload to the App. By providing content, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, distribute, and display such content within the App.
            </Typography>
            <br />
            <Typography variant="body1">
                <strong>6. Updates and Modifications</strong>
            </Typography>
            <br />
            <Typography variant="body1">
            We may release updates or modifications to the App to improve functionality or address security issues. You agree to promptly update the App to the latest version.
            </Typography>
            <br />
            <Typography variant="body1">
                <strong>7. Termination</strong>
            </Typography>
            <br />
            <Typography variant="body1">
            We reserve the right to terminate or suspend your access to the App at any time, for any reason, without notice.
            </Typography>
            <br />
            <Typography variant="body1">
                <strong>8. Disclaimer of Warranty</strong>
            </Typography>
            <br />
            <Typography variant="body1">
            The App is provided "as is" without warranties of any kind. We do not guarantee that the App will be error-free, secure, or uninterrupted.
            </Typography>
            <br />
            <Typography variant="body1">
                <strong>9. Limitation of Liability</strong>
            </Typography>
            <br />
            <Typography variant="body1">
            To the extent permitted by law, we shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of the App.
            </Typography>
            <br />
            <Typography variant="body1">
                <strong>10. Governing Law</strong>
            </Typography>
            <br />
            <Typography variant="body1">
            These Terms are governed by and construed in accordance with the laws of the United State of America, without regard to its conflict of law principles.
            </Typography>
            <br />
            <Typography variant="body1">
                <strong>11. Contact Information</strong>
            </Typography>
            <br />
            <Typography variant="body1">
            If you have any questions or concerns regarding these Terms, please use our Contact Us form.
            </Typography>
            <br />
            <Typography variant="body1">
            By using the Smart Phone Dash Cam App, you agree to these Terms of Service. Thank you for choosing Smart Phone Dash Cam!
            </Typography>

        </div>
    );
};

export default TermsOfService;