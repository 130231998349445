import React, { useState, useRef } from 'react';
import { Typography, TextField, Button, Snackbar, SnackbarContent, IconButton, CircularProgress, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

const Contact = () => {
    const MAX_NAME_LENGTH = 100;
    const MAX_EMAIL_LENGTH = 320;
    const MAX_MESSAGE_LENGTH = 1024;

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isRecaptchaValidated, setIsRecaptchaValidated] = useState(false);

    const reCaptchaRef = useRef(null); // Create a ref for the reCAPTCHA component


    const handleRecaptchaChange = async (value) => {
        console.log('reCAPTCHA value:', value);

        try {
            const response = await axios.post('https://validaterecaptcha-owro3e4wxa-uc.a.run.app', {
                token: value, // Pass the reCAPTCHA token to your API
            });

            console.log(response);

            // Check the response from your API
            if (response.status === 200) {
                console.log('reCAPTCHA validation successful');
                setIsRecaptchaValidated(true);
            } else {
                console.error('reCAPTCHA validation failed:', response.data.error);
                setIsRecaptchaValidated(false);
            }
        } catch (error) {
            console.error('Error validating reCAPTCHA:', error);
            // Handle the API call error
            setIsRecaptchaValidated(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Enforce maximum length based on the field
        let truncatedValue = value;
        if (name === 'name') {
            truncatedValue = value.slice(0, MAX_NAME_LENGTH);
        } else if (name === 'message') {
            truncatedValue = value.slice(0, MAX_MESSAGE_LENGTH);
        } else if (name === 'email') {
            truncatedValue = value.slice(0, MAX_EMAIL_LENGTH);
        }

        setFormData({
            ...formData,
            [name]: truncatedValue,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isRecaptchaValidated) {
            console.error('reCAPTCHA not validated. Submission aborted.');
            setToastMessage('reCAPTCHA not validated.  Submission aborted.');
            setIsError(true);
            setToastOpen(true);
            return;
        }

        setLoading(true); // Set loading to true while waiting for the API request

        try {
            // Make an API request using Axios
            await axios.post('https://sendcontactusform-owro3e4wxa-uc.a.run.app', { name: formData.name, email: formData.email, msg: formData.message });

            // Reset the reCAPTCHA component
            if (reCaptchaRef.current) {
                reCaptchaRef.current.reset();
            }

            // Display success toast
            setToastMessage('Message sent successfully!');
            setIsError(false);
            setToastOpen(true);

            // Optionally, you can reset the form after successful submission
            setFormData({
                name: '',
                email: '',
                message: '',
            });
        } catch (error) {
            // Display error toast
            setToastMessage('An error occurred while sending the message.');
            setIsError(true);
            setToastOpen(true);
        } finally {
            setLoading(false); // Set loading to false after the API request completes
        }
    };

    const handleReset = () => {
        setFormData({ name: '', email: '', message: '' });
    };

    const handleToastClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setToastOpen(false);
    };

    const remainingMessageCharacters = MAX_MESSAGE_LENGTH - formData.message.length;


    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Contact Us
            </Typography>
            <Typography variable="body1">
                Please fill out the following form to send us a message.  We will answer you back as soon as possible.
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    inputProps={{ spellCheck: false }} // Disable spell check for the name field
                    required
                />
                <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    inputProps={{ spellCheck: false }} // Disable spell check for the email field
                    required
                />
                <TextField
                    label="Message"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    helperText={`${remainingMessageCharacters} characters remaining`}
                    inputProps={{ style: { resize: 'both' } }} // Set the resize property
                    required
                />
                {/* Add ReCAPTCHA component - TODO turn on domain verification on recaptcha when going live */}
                <ReCAPTCHA
                    sitekey="6Lc440MpAAAAAIndcyPXHc9pgQsC-_Bvurfd9BY-"
                    onChange={handleRecaptchaChange}
                    ref={reCaptchaRef} // Assign the ref to the reCAPTCHA component
                />

                <Box mt={2} display="flex" justifyContent="start"> {/* Add space-between alignment */}
                    <Button type="submit" variant="contained" color="primary" disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : 'Submit'}
                    </Button>
                    <Box ml={2}>
                        <Button type="button" variant="outlined" color="secondary" onClick={handleReset}>
                            Reset
                        </Button>
                    </Box>
                </Box>
            </form>

            {/* Toast component for displaying success and error messages */}
            <Snackbar
                open={toastOpen}
                autoHideDuration={6000}
                onClose={handleToastClose}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleToastClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}


            >
                <SnackbarContent style={{
                    backgroundColor: isError ? '#ff0000' : '#4caf50',
                }}
                    message={toastMessage}
                />
            </Snackbar>
        </div>
    );
};

export default Contact;
