import React from 'react';
import { Typography } from '@mui/material';

const Privacy = () => {
    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Smart Phone Dash Cam Privacy Policy
            </Typography>
            <Typography variant="body1">
                Effective Date: 2 January 2024
            </Typography>
            <br />
            <Typography variant="body1">
                <strong>Introduction</strong>
            </Typography>
            <br />
            <Typography variant="body1">
                This Privacy Policy describes how Salter Diaz Solutions LLC ("we" or "us") collects, uses, and shares information in connection with the Smart Phone Dash Cam application ("App"). Please read this Privacy Policy carefully before using the App.
            </Typography>
            <br />
            <Typography variant="body1">
                <strong>Information We Collect</strong>
            </Typography>
            <br />
            <Typography variant="body1">
                We may collect the following types of information from you:
            </Typography>
            <ul>
                <li><strong>Personal Information:</strong> This includes your name, email address, and any other information that can be used to identify you, such as your phone number or device ID.</li>
                <li><strong>Payment Information:</strong> This includes your payment card information, such as your credit card number, expiration date, and billing address.</li>
                <li><strong>Usage Data:</strong> This includes information about your use of the App, such as the features you use, the frequency of your use, and the data recorded by the dash cam functionality.</li>
                <li><strong>Log Data:</strong> This includes information that is automatically collected by the App, such as your IP address, browser type, operating system, date and time of access, and referring/exit pages.</li>
            </ul>

            <Typography variant="body1">
                <strong>How We Collect Information</strong>
            </Typography>
            <br />
            <Typography variant="body1">
                We collect information from you in the following ways:
            </Typography>
            <ul>
                <li><strong>Through the App:</strong> We collect information about your use of the App through the App itself.</li>
                <li><strong>From payment processors: </strong> We collect payment information from you through secure payment processors.</li>
                <li><strong>From web forms: </strong> We may collect information from you through web forms on our website or within the App.</li>
            </ul>

            <Typography variant="body1">
                <strong>How We Use Information</strong>
            </Typography>
            <br />
            <Typography variant="body1">
                We use the information we collect to:
            </Typography>
            <ul>
                <li>Provide and improve the App and our services.</li>
                <li>Communicate with you, such as to send you updates and marketing messages.</li>
                <li>Analyze usage data to understand how the App is being used and to improve its performance.</li>
                <li>Detect, prevent, and address fraud and abuse.</li>
                <li>Comply with legal requirements.</li>
            </ul>

            <Typography variant="body1">
                <strong>Sharing of Information</strong>
            </Typography>
            <br />
            <Typography variant="body1">
            We may share the information we collect with third-party service providers who help us operate the App and provide our services. These service providers are bound by contractual obligations to keep your information confidential and to use it only for the purposes authorized by us.
            </Typography>
            <br />
            <Typography variant="body1">
            We may also share your information with:
            </Typography>
            <ul>
                <li>Law enforcement or government officials if it is required by law or if we believe in good faith that disclosure is necessary to protect our rights, the rights of others, or the public safety.</li>
                <li>Third-party partners for marketing purposes, but only with your consent.</li>
            </ul>

            <Typography variant="body1">
                <strong>Your Choices</strong>
            </Typography>
            <br />
            <Typography variant="body1">
            You have the following choices regarding your information:
            </Typography>
            <br />
            <Typography variant="body1">
            We may also share your information with:
            </Typography>
            <ul>
                <li>You can control the marketing emails you receive from us by following the unsubscribe instructions in the emails.</li>
                <li>You can access and update your personal information through the App or by contacting us.</li>
                <li>You can delete your account by contacting us.</li>
            </ul>

            <Typography variant="body1">
                <strong>Data Retention</strong>
            </Typography>
            <br />
            <Typography variant="body1">
            We will retain your information for as long as necessary to provide you with the App and our services, and to comply with legal requirements. After that, we will delete your information or anonymize it so that it can no longer be identified with you.
            </Typography>
            <br />
            <Typography variant="body1">
                <strong>Security</strong>
            </Typography>
            <br />
            <Typography variant="body1">
            We take the security of your information seriously and have implemented appropriate technical and organizational measures to protect it from unauthorized access, disclosure, alteration, or destruction. However, no website or internet transmission is completely secure, and we cannot guarantee the security of your information.
            </Typography>
            <br />
            <Typography variant="body1">
                <strong>Cookies and Similar Technologies</strong>
            </Typography>
            <br />
            <Typography variant="body1">
            We may use cookies and similar technologies to track your use of the App and to personalize your experience. You can control the use of cookies by adjusting the settings in your browser.
            </Typography>
            <br />
            <Typography variant="body1">
                <strong>Children's Privacy</strong>
            </Typography>
            <br />
            <Typography variant="body1">
            The App is not intended for use by children under the age of 13. We do not knowingly collect information from children under the age of 13. If you are a parent or guardian and you become aware that your child has provided us with information, please contact us.
            </Typography>
            <br />
            <Typography variant="body1">
                <strong>International Transfers</strong>
            </Typography>
            <br />
            <Typography variant="body1">
            Your information may be transferred to and processed in countries other than your own country. These countries may have different data protection laws than your own country. By using the App, you consent to this transfer of your information.
            </Typography>
            <br />
            <Typography variant="body1">
                <strong>Changes to This Privacy Policy</strong>
            </Typography>
            <br />
            <Typography variant="body1">
            We may update this Privacy Policy from time to time. We will post any changes on this page. We encourage you to review this Privacy Policy periodically for the latest information.
            </Typography>
            <br />
            <Typography variant="body1">
                <strong>Contact Us</strong>
            </Typography>
            <br />
            <Typography variant="body1">
            If you have any questions about this Privacy Policy, please contact us:
            </Typography>
            <ul>
                <li>By using the Contact Us link on this web site.</li>
                <li>By email: contactus@smartphonedashcam.com</li>
            </ul>

            <Typography variant="body1">
                <strong>California Residents:</strong>
            </Typography>
            <br />
            <Typography variant="body1">
            If you are a California resident, you have certain additional rights under the California Consumer Privacy Act (CCPA). You have the right to request access to your personal information, the right to request deletion of your personal information, and the right to opt out of the sale of your personal information. To exercise these rights, please contact us.
            </Typography>

        </div>
    );
};

export default Privacy;
