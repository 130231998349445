import React from 'react';
import { Typography, List, ListItem, ListItemText, Box } from '@mui/material';

const Home = () => {
    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Welcome to Smart Phone Dash Cam
            </Typography>
            <Typography variant="body1">
                Turn your smartphone into a powerful dash cam with our app. Ensure safety and record your journeys seamlessly.
            </Typography>
            <Box mt={2}>
                <Typography variant="h6" gutterBottom>
                    Key Features:
                </Typography>
                <List>
                    <ListItem button>
                        <ListItemText primary="Ability to record video with GPS location" />
                    </ListItem>
                    <ListItem button>
                        <ListItemText primary="Automatically start and stop based on vehicle movement" />
                    </ListItem>
                    <ListItem button>
                        <ListItemText primary="Automatically detect possible crash" />
                    </ListItem>
                    <ListItem button>
                        <ListItemText primary="Record to external storage" />
                    </ListItem>
                    <ListItem button>
                        <ListItemText primary="Lock recordings to prevent deletion" />
                    </ListItem>
                    <ListItem button>
                        <ListItemText primary="Upload videos to YouTube" />
                    </ListItem>
                </List>
            </Box>
        </div>
    );
};

export default Home;