import React from 'react';
import { redirect } from "react-router-dom";
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import { IconButton, Drawer, Typography, Container, List, ListItemIcon, ListItemButton, ListItemText, Paper, Grid, Box, createTheme, ThemeProvider, CssBaseline } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import HomeIcon from '@mui/icons-material/Home';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import SupportIcon from '@mui/icons-material/Support';
import PrivacyIcon from '@mui/icons-material/PrivacyTip';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MenuIcon from '@mui/icons-material/Menu';
import Contact from './components/Contact';
import Download from './components/Download';
import Support from './components/Support';
import Privacy from './components/Privacy';
import TermsOfService from './components/TermsOfService';
import DeleteMyData from './components/DeleteMyData';
import Home from './components/Home';

const ExternalLink = () => {
  return (
    <a href="https://salterdiazsolutions.com" target="_blank" rel="noopener noreferrer">
      Salter Diaz Solutions LLC
    </a>
  );
};

const Footer = () => {

  return (
    <Box component="footer" mt={4} p={2} bgcolor="#212121" color="#ffffff" display="flex" justifyContent="space-between" position="fixed" bottom={0} width="100%">
      <Typography variant="body2" color="textSecondary">
        &copy; {new Date().getFullYear()} <ExternalLink />
      </Typography>
      <Box display="flex" flexDirection="column" alignItems="start">
        <Link to="/privacy" color="textSecondary" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><PrivacyIcon style={{ marginRight: '4px' }} /> Privacy Policy</Link>
        <Link to="/terms-of-service" color="textSecondary" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><AssignmentIcon style={{ marginRight: '4px' }} /> Terms Of Service</Link>      </Box>
    </Box>
  );


};

const NotFound = () => {

  return (
    <div>
      <Typography variant='h1' gutterBottom>Oops! Page Not Found</Typography>
    <Typography>We seem to have lost our way. The page you're looking for is nowhere to be found.</Typography>
    </div>
  );


}

const LinkListItems = () => {
  return (
    <List>
      <ListItemButton component={Link} to="/">
        <ListItemIcon><HomeIcon /></ListItemIcon>
        <ListItemText primary="Home" />
      </ListItemButton>
      <ListItemButton component={Link} to="/download">
        <ListItemIcon><CloudDownloadIcon /></ListItemIcon>
        <ListItemText primary="Download" />
      </ListItemButton>
      <ListItemButton component={Link} to="/support">
        <ListItemIcon><SupportIcon /></ListItemIcon>
        <ListItemText primary="Support" />
      </ListItemButton>
      <ListItemButton component={Link} to="/contact">
        <ListItemIcon><ContactMailIcon /></ListItemIcon>
        <ListItemText primary="Contact" />
      </ListItemButton>
    </List>
  );
}


const App = () => {

  const [openDrawer, setOpenDrawer] = React.useState(false);
  const isSmallScreen = useMediaQuery('(max-width:768px)'); // Adjust the breakpoint as needed


  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const theme = createTheme({
    palette: {
      mode: 'dark', // Set the theme type to dark
    },
  });

  return (
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <div>
        <video
          autoPlay
          muted
          loop
          style={{ top: 0, left: 0, width: "100%", height: 240 }}
        >
          <source src="/header.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <Container>
          {/* Hamburger icon for mobile */}
          {isSmallScreen && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>)}
          {/* Drawer for mobile */}
          <Drawer
            anchor="left"
            open={openDrawer}
            onClose={handleDrawerClose}
          >
            <LinkListItems />
          </Drawer>
          <Grid container spacing={3}>
            {!isSmallScreen && (
              <Grid item xs={3}>
                <Paper square={false} style={{ padding: 20, marginTop: 20 }}>
                <LinkListItems />
                </Paper>
              </Grid>)}
            <Grid item xs={9}>
              <Routes>
                <Route path="/" element={<Home />} />
                {/* <Route path="/about" element={<About />} /> */}
                <Route path="/contact" element={<Contact />} />
                <Route path="/download" element={<Download />} />
                <Route path="/support" element={<Support />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/delete-my-data" element={<DeleteMyData />} />
                {/* Catch-all route for unknown paths */}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Grid>
          </Grid>
        </Container>

        <Footer />
      </div>
    </Router>
    </ThemeProvider>
  );
};

export default App;
