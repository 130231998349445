// DeleteMyData.js

import React, { useState, useRef } from 'react';
import { Typography, TextField, Button, Snackbar, SnackbarContent, CircularProgress, IconButton, Box } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';

const DeleteMyData = () => {

    const reCaptchaRef = useRef(null); // Create a ref for the reCAPTCHA component

    const [formData, setFormData] = useState({
        name: '',
        email: '',
    });

    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isRecaptchaValidated, setIsRecaptchaValidated] = useState(false);

    const handleToastClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setToastOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isRecaptchaValidated) {
            console.error('reCAPTCHA not validated. Submission aborted.');
            setToastMessage('reCAPTCHA not validated.  Submission aborted.');
            setIsError(true);
            setToastOpen(true);
            return;
        }

        setLoading(true); // Set loading to true while waiting for the API request

        try {
            // Make an API request using Axios
            await axios.post('https://sendcontactusform-owro3e4wxa-uc.a.run.app', { name: formData.name, email: formData.email, msg: 'Delete My Data Request' });

            // Reset the reCAPTCHA component
            if (reCaptchaRef.current) {
                reCaptchaRef.current.reset();
            }

            // Display success toast
            setToastMessage('Message sent successfully!');
            setIsError(false);
            setToastOpen(true);

            // Optionally, you can reset the form after successful submission
            setFormData({
                name: '',
                email: '',
            });
        } catch (error) {
            // Display error toast
            setToastMessage('An error occurred while sending the message.');
            setIsError(true);
            setToastOpen(true);
        } finally {
            setLoading(false); // Set loading to false after the API request completes
        }
    };

    const handleRecaptchaChange = async (value) => {
        console.log('reCAPTCHA value:', value);

        try {
            const response = await axios.post('https://validaterecaptcha-owro3e4wxa-uc.a.run.app', {
                token: value, // Pass the reCAPTCHA token to your API
            });

            console.log(response);

            // Check the response from your API
            if (response.status === 200) {
                console.log('reCAPTCHA validation successful');
                setIsRecaptchaValidated(true);
            } else {
                console.error('reCAPTCHA validation failed:', response.data.error);
                setIsRecaptchaValidated(false);
            }
        } catch (error) {
            console.error('Error validating reCAPTCHA:', error);
            // Handle the API call error
            setIsRecaptchaValidated(false);
        }
    };

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Delete My Data
            </Typography>
            <Box mb={2}>
                <Typography variable="body1">
                    Please enter your name and email address. We will remove all of your data and reply back within 48 hours.
                </Typography>
            </Box>
            <Typography variable="body1">
                Please note you will need to uninstall the app from your devices.  If the app is run again after this request is made, then you will need to re-request for your data to be removed.
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    required
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    margin="normal"
                />
                <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    required
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    margin="normal"
                />
                {/* Add ReCAPTCHA component - TODO turn on domain verification on recaptcha when going live */}
                <ReCAPTCHA
                    sitekey="6Lc440MpAAAAAIndcyPXHc9pgQsC-_Bvurfd9BY-"
                    onChange={handleRecaptchaChange}
                    ref={reCaptchaRef} // Assign the ref to the reCAPTCHA component
                />
                <Box mt={2}>
                    <Button type="submit" variant="contained" color="primary" >
                        {loading ? <CircularProgress size={24} /> : 'Delete My Data'}
                    </Button>
                </Box>
            </form>

            {/* Toast component for displaying success and error messages */}
            <Snackbar
                open={toastOpen}
                autoHideDuration={6000}
                onClose={handleToastClose}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleToastClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}


            >
                <SnackbarContent style={{
                    backgroundColor: isError ? '#ff0000' : '#4caf50',
                }}
                    message={toastMessage}
                />
            </Snackbar>
        </div>
    );
};

export default DeleteMyData;
