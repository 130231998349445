import { Typography } from '@mui/material';
import React from 'react';

const Download = () => {
  return (
    <div>
      <Typography variant="h4" gutterBottom>Download Smart Phone Dash Cam</Typography>
      {/* Add download links and instructions */}
      <Typography variant="body1">Coming soon...</Typography>
    </div>
  );
};

export default Download;
